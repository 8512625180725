@use '~/styles/modules' as *;

.CollectionCard {
  font: $p1;
  color: var(--text-color);

  background-color: var(--background2);
  box-shadow: $cardShadow1, $cardShadow2;
  border-radius: 16px;
  overflow: hidden;
  transform: translateZ(0); //For border-radius to work on Safari

  @include flexContainer(column);

  &:hover {
    background-color: rgb(255 255 255 / 5%);
  }

  &:hover > span {
    box-shadow: inset 0px 0px 0px 1px rgb(255 255 255 / 10%);
    background: radial-gradient(
      80% 40% at 50% 0%,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .container {
    width: 100%;
  }

  .top {
    padding-bottom: 33.3334%;
    height: 100%;
    position: relative;

    &.nonMobile {
      padding-bottom: 100%;
    }

    @include small-screens() {
      padding-bottom: 100%;
    }

    .imageGrid {
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      margin: -1px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;

      &.nonMobile {
        flex-wrap: wrap;
      }
      @include small-screens() {
        flex-wrap: wrap;
      }
    }

    .imageContainer {
      position: relative;
      width: 50%;
      flex-grow: 1;
      border: 1px solid var(--background2);
    }
  }

  .bottom {
    padding: 1.6rem;
    height: 8.3rem;
    position: relative;
    @include flexContainer(column, center, flex-start);

    h2 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      text-transform: capitalize;
      font: $h2;
      margin-bottom: 0.2rem;
    }
    .chip {
      position: absolute;
      right: 1.6rem;
      top: -1.5rem;
      z-index: 1;
    }

    .artistRow {
      @include flexContainer(row);
      margin-top: 0.2rem;
      letter-spacing: 0.11px;
      text-transform: uppercase;

      p {
        font-size: 11px;
        font-weight: 600;
        color: var(--text-color-faded);
      }

      h3 {
        font-size: 11px;
        font-weight: 600;
        margin: 0 auto 0 0.4rem;
      }
    }
  }
}

.overlay {
  box-shadow: inset 0px 0px 0px 1px rgb(255 255 255 / 5%);
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  transform: translateZ(0); //For border-radius to work on Safari
  transition: all 0.1s ease-in;
}
